import { render, staticRenderFns } from "./crcManagement.vue?vue&type=template&id=1ef0b93c&scoped=true&"
import script from "./crcManagement.js?vue&type=script&lang=js&"
export * from "./crcManagement.js?vue&type=script&lang=js&"
import style0 from "./crcManagement.less?vue&type=style&index=0&id=1ef0b93c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ef0b93c",
  null
  
)

export default component.exports