
export default {
	name: "crcManagement",
	data() {
		return {
			httpHeader: {
				token: this.getCookie("token")
			},
			sex1: "girl",
			newEdit: false,
			removeAlert: false,
			list: "",
			editId: "",
			name: "",
			sex: "0",
			years: "",
			education: "",
			major: "",
			employmentTime: "",
			idNumber: "",
			phone: '', //手机号
			man: false,
			woman: false,
			editRoNew: "",
			headImg: "",
			removeId: "",
			removeItemIndex: "",
			total: 0,
			pageSize: 0,
			currentPage: 1,
			headImageUrl: "",
			dufImg: "",
			Imgurl: XyhConf.HglSHost + "/API/GLS/uploadFile",
			upExcelUrl: XyhConf.HglSHost + '/API/PLAT/uplaodExcel',
			presence: false, //身份id不重复false/重复true
			todayTime: "", //当天的日期
			isRepeat: '', //手机号验证重复
			hospitalArr: [], //机构
			instId: '', //机构id
			restaurants: [],
			enterpriseId: '', //somid
			enterpriseName: '',//smo名字
			timeout: null, //时间
		};
	},
	created() {
		this.token = this.getCookie("token");
		this.instId = this.getCookie('instId');
		this.todayTime = this.curentTime()
		this.getCrcMang();
		this.loadAll()
	},
	mounted() {
		//			this.loadAll()
	},

	methods: {
		curentTime() {
			var now = new Date();
			var year = now.getFullYear(); //年
			var month = now.getMonth() + 1; //月
			var day = now.getDate(); //日

			var hh = now.getHours(); //时
			var mm = now.getMinutes(); //分

			var ddate = year + "-";

			if (month < 10) ddate += "0";

			ddate += month + "-";

			if (day < 10) ddate += "0";

			ddate += day;
			return ddate;
		},

		getCrcMang() {
			var _this = this;
			_this.$http
				.post(
					"/API/PLAT/crcUserListPage", {
					page: _this.currentPage,
					instId: _this.instId
				}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (response) {
					if (response.data.code == 200) {
						//						console.log(response.data)
						_this.list = response.data.data.records;
						_this.total = response.data.data.total;
						_this.pageSize = response.data.data.size;
						_this.currentPage = response.data.data.current;
						for (var i = 0; i < _this.list.length; i++) {
							if (!_this.list[i].headImg) {
								_this.list[i].headImg = "https://qinghua-1256157065.cos.ap-beijing.myqcloud.com/5749829dc5c04cc99e2cd984e7f5bbf4_head.png"
							}
						}
					}
				});
		},
		//关闭弹框
		closeAlter() {
			this.newEdit = false;
		},
		//点击添加
		newHandel() {
			var _this = this;
			this.newEdit = true;
			this.editRoNew = 1;
			this.name = "";
			this.years = "";
			this.education = "";
			this.major = "";
			this.employmentTime = "";
			this.idNumber = "";
			this.phone = "";
			this.headImageUrl =
				"https://qinghua-1256157065.cos.ap-beijing.myqcloud.com/5749829dc5c04cc99e2cd984e7f5bbf4_head.png";
			if (_this.sex == 1) {
				_this.man = true;
				_this.woman = false;
			} else if (_this.sex == 0) {
				_this.woman = true;
				_this.man = false;
			}
			_this.$http
				.post(
					"/API/GLS/institutionList", {}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (response) {
					if (response.data.code == 200) {
						_this.hospitalArr = response.data.data;
					}
				})
		},
		//编辑
		edit(id) {
			var _this = this;
			this.editId = id;
			_this.$http
				.post(
					"/API/PLAT/crcUserInfo", {
					id: id
				}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (response) {
					if (response.data.code == 200) {
						//						console.log(response)
						_this.name = response.data.data.name;
						_this.sex = response.data.data.sex;
						_this.years = response.data.data.years;
						_this.education = response.data.data.education;
						_this.major = response.data.data.major;
						_this.employmentTime = response.data.data.employmentTime;
						//						console.log(response.data.data.employmentTime)
						_this.idNumber = response.data.data.idNumber;
						_this.phone = response.data.data.phone;
						_this.headImageUrl = response.data.data.headImg;
						_this.enterpriseName = response.data.data.enterpriseName;
						if (_this.sex == 1) {
							_this.man = true;
							_this.woman = false;
						} else {
							_this.woman = true;
							_this.man = false;
						}
						if (!_this.headImageUrl) {
							_this.headImageUrl = "https://qinghua-1256157065.cos.ap-beijing.myqcloud.com/5749829dc5c04cc99e2cd984e7f5bbf4_head.png"
						}
					}
				});
			this.newEdit = true;
			this.editRoNew = 2;
		},
		handleAvatarSuccess(res, file) {
			if (res.code == 200) {
				//					console.log(res.data)
				this.headImageUrl = res.data.name;
			}
			//		        console.log(this.headImageUrl)
		},
		beforeAvatarUpload(file) {
			const isJPG = file.type === "image/jpeg" || "image/png";
			const isLt2M = file.size / 1024 / 1024 < 2;
			if (!isJPG) {
				this.$message.error("上传头像图片只能是 JPG 格式!");
			}
			if (!isLt2M) {
				this.$message.error("上传头像图片大小不能超过 2MB!");
			}
			return isJPG && isLt2M;
		},
		sexfn(sex) {
			if (sex == "boy") {
				this.sex1 = sex;
				this.sex = 1;
				this.man = true;
				this.woman = false;
			} else if (sex == "girl") {
				this.sex1 = sex;
				this.sex = 0;
				this.man = false;
				this.woman = true;
			}
		},
		changeIdNumber() {
			var _this = this;
			_this.$http
				.post(
					"/API/PRO/IDCardIsRepeat", {
					idCard: _this.idNumber
				}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (response) {
					if (response.data.code == 200) {
						_this.presence = response.data.data.presence;
						if (_this.presence) {
							_this.defeated("该身份证号码已存在");
						}
					}
				});
		},
		changePhone() {
			var _this = this;
			_this.$http
				.post(
					"/API/PLAT/numberVerification", {
					phoneNumber: _this.phone
				}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (response) {
					if (response.data.code == 200) {
						_this.isRepeat = response.data.data.isRepeat;
						if (_this.isRepeat) {
							_this.defeated("该手机号码格式不正确或已存在");
						}
					}
				});
		},
		querySearch(queryString, cb) {
			var restaurants = this.restaurants;
			var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
			// 调用 callback 返回建议列表的数据
			cb(results);
		},
		createFilter(queryString) {
			return (restaurant) => {
				return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
			};
		},
		handleSelect(item) {
			this.enterpriseId = item.address;
		},
		loadAll() {
			var _this = this;
			_this.$http.get(
				"/API/PLAT/findEnterpriseList", {},
			)
				.then(function (response) {
					if (response.data.code == 200) {
						_this.restaurants = response.data.data;
					}
				});
		},
		//点击弹框确定
		submitAlter() {
			var _this = this;
			//				console.log(_this.name)
			//				console.log(_this.sex)
			//				console.log(_this.idNumber)
			//				console.log(_this.years)
			//				console.log(_this.education)
			//				console.log(_this.major)
			//								console.log(_this.enterpriseId)
			if (
				_this.name && (_this.sex == 0 || _this.sex == 1) &&
				_this.education &&
				_this.major &&
				_this.employmentTime &&
				_this.idNumber &&
				_this.phone &&
				_this.years &&
				_this.major) {
				if (_this.idNumber.length == 18) {
					if (!_this.presence) {
						//							console.log('go')
						//手机号验证长度
						if (_this.phone.length == 11) {
							if (_this.isRepeat) {
								_this.defeated("该手机号码格式不正确或已存在");
							} else {
								if (_this.editRoNew == 1) {
									_this.$http
										.post(
											"/API/PLAT/addCrc", {
											name: _this.name,
											headImg: _this.headImageUrl,
											sex: _this.sex ? _this.sex : "0",
											idNumber: _this.idNumber,
											phoneNumber: _this.phone,
											years: _this.years,
											education: _this.education,
											major: _this.major,
											employmentTime: _this.employmentTime,
											enterpriseId: _this.instId ? _this.enterpriseId : ''
										}, {
											headers: {
												token: _this.token
											}
										}
										)
										.then(function (response) {
											if (response.data.code == 200) {
												_this.getCrcMang();
												_this.newEdit = false;
											}
										});
								} else {
									_this.$http
										.post(
											"/API/PLAT/updateCrc", {
											id: _this.editId,
											name: _this.name,
											headImg: _this.headImageUrl,
											sex: _this.sex,
											idNumber: _this.idNumber,
											phoneNumber: _this.phone,
											years: _this.years,
											education: _this.education,
											major: _this.major,
											employmentTime: _this.employmentTime,
											enterpriseId: _this.instId ? _this.enterpriseId : ''
										}, {
											headers: {
												token: _this.token
											}
										}
										)
										.then(function (response) {
											if (response.data.code == 200) {
												_this.getCrcMang();
												_this.newEdit = false;
											}
										});
								}


							}
						} else {
							_this.defeated("请输入11位手机号码");
						}
					} else {
						_this.defeated("该身份证号码已存在");
					}
				} else {
					_this.defeated("身份证号码格式不正确");
				}
			} else {
				_this.defeated("请填写完整信息");
			}
		},
		//删除信息
		delListItem(id, index) {
			var _this = this;
			this.removeId = id;
			this.removeItemIndex = index;
			this.removeAlert = true;
		},
		yes() {
			var _this = this;
			this.removeAlert = false;
			_this.$http
				.post(
					"/API/PLAT/deleteCrc", {
					id: _this.removeId
				}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (response) {
					if (response.data.code == 200) {
						//						console.log(response)
						_this.list.splice(_this.removeItemIndex, 1);
					}
				});
		},
		//点击分页
		currentPageChanged(currentPage) {
			this.currentPage = currentPage;
			this.getCrcMang();
		},
		no() {
			this.removeItemIndex = "";
			this.removeAlert = false;
		},
		toPar(id) {
			this.$store.commit("getId", id);
			this.$router.push({
				name: "crcParticulars",
				params: {
					id: id
				}
			});
		},
		//模板下载
		templateDownLoad() {
			var _this = this;
			_this.$http.get('/API/PLAT/templateDown', {
				responseType: 'blob'
			}, {}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					let filename = "CRC模板.xlsx";
					_this.fileDownload(response.data, filename);
				})
		},
		fileDownload(data, fileName) {
			console.log(2)
			let blob = new Blob([data], {
				type: "application/octet-stream"
			});
			let filename = fileName || "filename.xls";
			if (typeof window.navigator.msSaveBlob !== "undefined") {
				window.navigator.msSaveBlob(blob, filename);
			} else {
				var blobURL = window.URL.createObjectURL(blob);
				var tempLink = document.createElement("a");
				tempLink.style.display = "none";
				tempLink.href = blobURL;
				tempLink.setAttribute("download", filename);
				if (typeof tempLink.download === "undefined") {
					tempLink.setAttribute("target", "_blank");
				}
				document.body.appendChild(tempLink);
				tempLink.click();
				document.body.removeChild(tempLink);
				window.URL.revokeObjectURL(blobURL);
			}
		},
		//批量导入
		handleRemove(file, fileList) {
			console.log(file, fileList);
		},
		handlePreview(file) {
			console.log(file);
		},
		handleExceed(files, fileList) {
			this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
		},
		beforeRemove(file, fileList) {
			return this.$confirm(`确定移除 ${file.name}？`);
		},
		importing() {
			var _this = this;
			console.log(_this.$refs.fileUrl.files)
			_this.$http.post('/API/PLAT/uplaodExcel', {
				file: _this.$refs.fileUrl.files
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					console.log(response)
					if (response.data.code == 200) {
						console.log(response)
					}
				})
		}
	}
};
